import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { useState, useEffect } from "react";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { ContentPlusDecor } from "../../../components/widgets/content-plus-decor";
import { FaEnvelope } from "react-icons/fa";
import { ButtonPrimary } from "../../../components/widgets/button";
import { Accordion } from "../../../components/widgets/accordion";
export const Archive = ({
  className
}) => {
  const [theArchive, setArchive] = useState("loading...");
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://turingtrader.us20.list-manage.com/generate-js/?u=c6214e7ef367ae327105288e4&fid=8710&show=12";
    script.async = true;
    // FIXME: this is a hack. We are overwriting
    // document.write... without even knowing what
    // the possible side-effects of doing so are.
    document.write = str => setArchive(str);
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return <p className={className}>
        09/01/2023 - <a href="2023/09">September Update</a>
        <span dangerouslySetInnerHTML={{
      __html: theArchive
    }} />
    </p>;
};
export const SignupOld = ({
  className
}) => {
  const [email, setEmail] = useState("");
  return <div id="mc_embed_signup">
            <form action="https://TuringTrader.us20.list-manage.com/subscribe/post?u=c6214e7ef367ae327105288e4&amp;id=a05ee40673" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate className={className}>
                <div id="mc_embed_signup_scroll" css={`
                        display: flex;
                    `}>
                    {/*<div class="indicates-required"><span class="asterisk">*</span> indicates required</div>*/}
                    <div className="mc-field-group">
                        <label htmlFor="mce-EMAIL">Email:&nbsp;</label>
                        <input type="email" value={email} onChange={event => setEmail(event.target.value)} name="EMAIL" className="required email" id="mce-EMAIL" />
                    </div>
                    <div id="mce-responses" className="clear foot">
                        <div className="response" id="mce-error-response" style={{
            "display": "none"
          }}></div>
                        <div className="response" id="mce-success-response" style={{
            "display": "none"
          }}></div>
                    </div>
                    <div style={{
          "position": "absolute",
          "left": "-5000px"
        }} aria-hidden="true"><input type="text" name="b_c6214e7ef367ae327105288e4_a05ee40673" tabIndex="-1" value="" /></div>
                    <div className="optionalParent">
                        <div className="clear foot">
                            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" css={`
                                    color: ${props => props.theme.colors.text};
                                    font-size: ${props => props.small === true ? "0.70rem" : "smaller"};
                                    font-weight: 700;
                                    text-transform: uppercase; 
                                    letter-spacing: 0.05em;
                                    text-decoration: none;
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                    padding: ${props => props.small === true ? "0.0rem 0.2rem 0.0rem 0.2rem" : "0.25rem 0.5rem 0.25rem 0.5rem"};
                                    margin-left: 1rem;
                                    margin-right: 1rem;
                                    min-width: 3rem;
                                    text-align: center;
                                    border: 0.10rem solid;
                                    border-radius: 0.5rem;
                                    border-style: none;
                                    cursor: pointer;
                                    color: white;
                                    background-color: ${props => props.theme.colors.cta};
                                    border-color: ${props => props.theme.colors.cta};
                                    border-style: solid;
                                    box-shadow: ${props => props.theme.shadows.box};
                                    text-shadow: 0px 0px 3px ${props => props.theme.colors.text};
                                `} />
                            {/*<p class="brandingLogo"><a href="http://eepurl.com/h5nLRL" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"/></a></p>*/}
                        </div>
                    </div>
                </div>
            </form>
        </div>;
};
export const SignupEmbed = ({
  className
}) => <div data-form-uid="aOW4VAQg"></div>;
export const Signup = ({
  className
}) => <ButtonPrimary text="subscribe" to="https://turingtrader.outseta.com/forms/aOW4VAQg" mdxType="ButtonPrimary" />;
export const _frontmatter = {};
const layoutProps = {
  Archive,
  SignupOld,
  SignupEmbed,
  Signup,
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">









    <h1>{`Newsletter Archive`}</h1>
    <Breadcrumbs to="/company/" text="Company" mdxType="Breadcrumbs" />
    <ContentPlusDecor decor={<FaEnvelope mdxType="FaEnvelope" />} mdxType="ContentPlusDecor">
      <p>{`About once a month, we send our members a newsletter with updates regarding our site. Read the most-recent issues here:`}</p>
      <ul>
        <li parentName="ul">{`01/03/2024 - `}<a parentName="li" {...{
            "href": "/company/newsletter/2024/01/"
          }}>{`January Update`}</a></li>
        <li parentName="ul">{`12/01/2023 - `}<a parentName="li" {...{
            "href": "/company/newsletter/2023/12/"
          }}>{`December Update`}</a></li>
        <li parentName="ul">{`11/01/2023 - `}<a parentName="li" {...{
            "href": "/company/newsletter/2023/11/"
          }}>{`November Update`}</a></li>
      </ul>
      <Accordion title="Older Issues" mdxType="Accordion">
        <Accordion title="2023" mdxType="Accordion">
          <table><tr><td>
                <p><a parentName="p" {...{
                    "href": "/company/newsletter/2023/12/"
                  }}>{`December`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "/company/newsletter/2023/11/"
                  }}>{`November`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "/company/newsletter/2023/10/"
                  }}>{`October`}</a></p>
              </td></tr><tr><td>
                <p><a parentName="p" {...{
                    "href": "/company/newsletter/2023/09/"
                  }}>{`September`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "/company/newsletter/2023/08/"
                  }}>{`August`}</a></p>
              </td><td>
                <p>{`-- / --`}</p>
              </td></tr><tr><td>
                <p><a parentName="p" {...{
                    "href": "/company/newsletter/2023/06/"
                  }}>{`June`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "/company/newsletter/2023/05/"
                  }}>{`May`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "/company/newsletter/2023/04/"
                  }}>{`April`}</a></p>
              </td></tr><tr><td>
                <p><a parentName="p" {...{
                    "href": "/company/newsletter/2023/03/"
                  }}>{`March`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "/company/newsletter/2023/02/"
                  }}>{`February`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "/company/newsletter/2023/01/"
                  }}>{`January`}</a></p>
              </td></tr>
          </table>
        </Accordion>
        <Accordion title="2022" mdxType="Accordion">
          <table><tr><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/0a9cdeb50f4c/december"
                  }}>{`December`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/5cd0c1339bfc/november"
                  }}>{`November`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/e28b74d4e976/october"
                  }}>{`October`}</a></p>
              </td></tr><tr><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/80f274b24930/september"
                  }}>{`September`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/bd7006d24d3e/august"
                  }}>{`August`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/5ef3f3dc47ce/july?e=%5BUNIQID%5D"
                  }}>{`July`}</a></p>
              </td></tr><tr><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/6c0bb72fbe85/march-16234654?e=%5BUNIQID%5D"
                  }}>{`June`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/13ce84b2fdff/march-16077146?e=%5BUNIQID%5D"
                  }}>{`May`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/661c92d51c10/march-15931054?e=%5BUNIQID%5D"
                  }}>{`April`}</a><br /></p>
              </td></tr><tr><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/d3031651d5e8/march?e=%5BUNIQID%5D"
                  }}>{`March`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/c66e6ae5541a/february?e=%5BUNIQID%5D"
                  }}>{`February`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/14cefb7e23e3/january?e=%5BUNIQID%5D"
                  }}>{`January`}</a></p>
              </td></tr></table>
        </Accordion>
        <Accordion title="2021" mdxType="Accordion">
          <table><tr><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/c8a8d1859d02/december?e=%5BUNIQID%5D"
                  }}>{`December`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/a8eebb5bfd15/november?e=%5BUNIQID%5D"
                  }}>{`November`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/320ae3d0b9ea/october?e=%5BUNIQID%5D"
                  }}>{`October`}</a></p>
              </td></tr><tr><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/d056dbf783cd/september?e=%5BUNIQID%5D"
                  }}>{`September`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/5c43ba0ac177/august?e=%5BUNIQID%5D"
                  }}>{`August`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/1e2bcaa8251a/july?e=%5BUNIQID%5D"
                  }}>{`July`}</a></p>
              </td></tr><tr><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/babffa07c932/june?e=%5BUNIQID%5D"
                  }}>{`June`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/f499942a8008/may?e=%5BUNIQID%5D"
                  }}>{`May`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/cbf3614382ba/april?e=%5BUNIQID%5D"
                  }}>{`April`}</a></p>
              </td></tr><tr><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/5dc91ab5d34d/march?e=%5BUNIQID%5D"
                  }}>{`March`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/80035b0f94e1/february?e=%5BUNIQID%5D"
                  }}>{`February`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/be75f6594a06/january?e=%5BUNIQID%5D"
                  }}>{`January`}</a></p>
              </td></tr></table>
        </Accordion>
        <Accordion title="2020" mdxType="Accordion">
          <table><tr><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/8cf2abca630e/december?e=%5BUNIQID%5D"
                  }}>{`December`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/1a4e09dc1ef7/november?e=%5BUNIQID%5D"
                  }}>{`November`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/ecedd92eef61/october?e=%5BUNIQID%5D"
                  }}>{`October`}</a></p>
              </td></tr><tr><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/e0afdac79e07/september-2020?e=%5BUNIQID%5D"
                  }}>{`September`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/11e89f64493a/august?e=%5BUNIQID%5D"
                  }}>{`August`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/e627d7beea55/may-update-12302866?e=%5BUNIQID%5D"
                  }}>{`July`}</a></p>
              </td></tr><tr><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/34cd70222dce/may-update-12287825?e=%5BUNIQID%5D"
                  }}>{`June`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/e8c9dd20e386/may-update?e=%5BUNIQID%5D"
                  }}>{`May`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/ba9f9b303352/april-update?e=%5BUNIQID%5D"
                  }}>{`April`}</a></p>
              </td></tr><tr><td>
                <p>{`-- / --`}</p>
              </td><td>
                <p>{`-- / --`}</p>
              </td><td>
                <p>{`-- / --`}</p>
              </td></tr></table>
        </Accordion>
        <Accordion title="2019" mdxType="Accordion">
          <table><tr><td>
                <p><a parentName="p" {...{
                    "href": "https://mailchi.mp/a0a9d39fd537/us-economy-indicators?e=%5BUNIQID%5D"
                  }}>{`December`}</a></p>
              </td><td>
                <p><a parentName="p" {...{
                    "href": "https://us20.admin.mailchimp.com/campaigns/show?id=12069405"
                  }}>{`November`}</a></p>
              </td><td>
                <p>{`-- / --`}</p>
              </td></tr></table>
        </Accordion>
      </Accordion>
      <p>{`We’d love to keep you updated on our latest news and updates. To join our newsletter, please click the button:`}</p>
      <Signup css={`margin-left:2em;`} mdxType="Signup" />
      <br />
      <br />
      <br />
    </ContentPlusDecor>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      